import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./OurProjects.module.scss";

function OurProjects() {
  const carouselResponsive = {
    0: {
      items: 1,
      slideBy: 1,
    },
    576: {
      items: 1,
      slideBy: 1,
    },
    768: {
      items: 2,
      slideBy: 2,
    },
    992: {
      items: 3,
      slideBy: 3,
    },
    1200: {
      items: 3,
      slideBy: 3,
    },
  };
  function padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  }

  var loop = false;
  const counter = (event) => {
    var items = event.item.count;
    var slideBy = event.page.size;

    var indexCount = 2;
    if (slideBy == 1) {
      indexCount = 1;
    }

    if (loop == true) {
      var item = event.item.index - 2;
    } else {
      var item = event.item.index + indexCount;
    }

    // it loop is true then reset counter from 1
    if (item > items) {
      item = item - items;
    }

    item = Math.ceil(item / slideBy);
    items = Math.ceil(items / slideBy);

    $(".owl-dots").html(padDigits(item, 2) + " / " + padDigits(items, 2));
  };
  return (
    <section
      className={`${styles["section"]} ${styles["section__projects"]} ${styles["__section_normal_tb_padding"]}`}
    >
      <div className={`${styles["container"]}`}>
        <div className={`${styles["container__row"]}`}>
          <div className={`${styles["container__col_xs_12"]}`}>
            <h2 className={`${styles["container__heading"]}`}>Our Projects</h2>
            <div className={`${styles["container__row"]}`}>
              <div className={`${styles["container__col_md_7"]}`}>
                <p>
                  Our Projects We create one-of-a-kind, unique and interactive
                  digital experiences that are customized to each brand. We
                  propel your marketing vision with the right blend of
                  creativity and technology. Check out our recent projects with
                  Global Brands across the globe. Let's collaborate to build an
                  amazing digital experience.
                </p>
              </div>
            </div>
          </div>
          {typeof window !== "undefined" && OwlCarousel && (
            <OwlCarousel
              className={`${styles["owl_theme"]} ${styles["our_projects_carousel"]}`}
              margin={30}
              slideBy={"page"}
              autoplay={true}
              autoplayTimeout={6000}
              autoplaySpeed={1000}
              autoplayHoverPause={true}
              stagePadding={35}
              responsive={carouselResponsive}
              nav={true}
              dots={true}
              onInitialized={counter}
              onTranslated={counter}
            >
              <div className={`${styles["owl_carousel__item"]}`}>
                <img
                  className={`${styles["owl_carousel__image"]}`}
                  src="../../solutions/solutions/projects/geotab-communities.png"
                  alt="Geotab Communities"
                ></img>
                <div className={`${styles["owl_carousel__content"]}`}>
                  <a
                    className={`${styles["owl_carousel__link"]} `}
                    // href="#modal"
                  >
                    <h5 className={`${styles["owl_carousel__heading"]}`}>
                      Geotab Communities
                    </h5>
                  </a>
                  <p>
                    With expertise on Support domain, helped Geotab Support
                    launch a Unified Support Experience.
                  </p>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <img
                  className={`${styles["owl_carousel__image"]}`}
                  src="../../solutions/solutions/projects/nuvo-communities.png"
                  alt="Nuvo Communities"
                />
                <div className={`${styles["owl_carousel__content"]}`}>
                  <a
                    className={`${styles["owl_carousel__link"]}`}
                    // href="#modal"
                  >
                    <h5 className={`${styles["owl_carousel__heading"]}`}>
                      Nuvo Communities
                    </h5>
                  </a>
                  <p>
                    A customer community focused at facilitating conversations
                    between dentists how to get the best out of Nuvo solutions.
                  </p>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <img
                  className={`${styles["owl_carousel__image"]}`}
                  src="../../solutions/solutions/projects/citrix-developer-community.png"
                  alt="Citrix Developer Community"
                />
                <div className={`${styles["owl_carousel__content"]}`}>
                  <a
                    className={`${styles["owl_carousel__link"]}`}
                    // href="#modal"
                  >
                    <h5 className={`${styles["owl_carousel__heading"]}`}>
                      Citrix Developer Community
                    </h5>
                  </a>
                  <p>
                    Grass root community to nurture Citrix product conversations
                    between Citrix subject Matter experts.
                  </p>
                </div>
              </div>
              <div className={`${styles["owl_carousel__item"]}`}>
                <img
                  className={`${styles["owl_carousel__image"]}`}
                  src="../../solutions/solutions/projects/ollmoo-connect.png"
                  alt="Ollmoo - Connect"
                />
                <div className={`${styles["owl_carousel__content"]}`}>
                  <a
                    className={`${styles["owl_carousel__link"]}`}
                    // href="#modal"
                  >
                    <h5 className={`${styles["owl_carousel__heading"]}`}>
                      Ollmoo - Connect
                    </h5>
                  </a>
                  <p>
                    Portal for onboarding new recruits with a seamless marketing
                    to application experience.{" "}
                  </p>
                </div>
              </div>
            </OwlCarousel>
          )}
        </div>
      </div>
    </section>
  );
}

export default OurProjects;
