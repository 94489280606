import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function TechExpertise() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--technologies --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">
              Technologies we are experts at
            </h2>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/salesforce-community-cloud.png"
                alt="Salesforce Community Cloud"
              />
              <h5 className="card__title">Salesforce Community Cloud</h5>
              <div className="card__description">
                Create Complex, enterprise-grade, post-purchase experience
                including knowledgebase, peer-to-peer community, case
                management, and partner portal with out-of-the-box templates
                while incrementally customizing to match your brand story and
                purpose.
                <br />
                <br />
                <b>
                  A great place to start for an enterprise already invested in
                  Salesforce
                </b>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/discourse.png"
                alt="Discourse"
              />
              <h5 className="card__title">Discourse</h5>
              <div className="card__description">
                Discourse is a modern opensource community solution with a long
                list of purpose-built themes and plug-ins powered by the
                community of users and developers. <br />
                <br />
                <b>
                  For brands and teams looking to cut the complexity and create
                  a buzzing community.
                </b>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0); "
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
          <div className="container__col-md-4">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/technologies/circle.png"
                alt="Unity"
              />
              <h5 className="card__title">Circle.so</h5>
              <div className="card__description">
                Focused SaaS-based community solution for smaller brands, teams,
                and groups looking for a simple, easy to start, maintain, and
                yet scalable solution.
                <br />
                <br />
                <b>
                  This is a no-code platform with free and paid versions priced
                  aptly for smaller teams and product companies.
                </b>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                start a project
              </a>
            </div>
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="online-communities-Tech-expertise"
        page="online-communities"
       
      />
    </section>
  );
}

export default TechExpertise;
