import React from "react";
import TechExpertise from "../TechExpertise_OnlineCommunities/TechExpertise";
import OurProjects from "../OurProjects_OnlineCommunities/OurProjects";

function MultiSectionOnlineCommunities() {
  return (
    <div className="section section--bg-dot-curve">
      <TechExpertise />
      <OurProjects />
    </div>
  );
}

export default MultiSectionOnlineCommunities;
