import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function OnlineCommunities() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner section--gray-bg">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading">Online Communities </h1>
            <h2 className="container__sub-heading">
              Get{" "}
              <span className="--red-color">
                the following your brand deserves
              </span>{" "}
              with online communities
            </h2>
            <h6 className="container__content">
              Be it a brand, support, or a practice community for an enterprise,
              startup, or a non profit, we have the right solutions and
              expertise to theme, customize, integrate and{" "}
              <span className="--red-color">
                ensure the expected ROI is achieved.
              </span>
            </h6>
            <button className="container__button" onClick={openModal}>
              LET’S TALK
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/solutions/online-communities.png"
              alt="Online Communities"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="OnlineCommunities-Banner"
        page="OnlineCommunities"
       
      />
    </section>
  );
}

export default OnlineCommunities;
