import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import OnlineCommunities from "../components/Solutions/OnlineCommunities/OnlineCommunities";
import MultiSectionOnlineCommunities from "../components/Solutions/MultiSectionOnlineCommunities/MultiSectionOnlineCommunities";
// import OurProjects from "../components/Solutions/OurProjects_OnlineCommunities/OurProjects";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import { Helmet } from "react-helmet";
function solutionsOnlineCommunities() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies -   Online Communities      </title>
       <meta
         name="description"
         content="Be it a brand, support, or a practice community for an enterprise, startup, or a non profit, we have the right solutions and expertise to theme, customize, integrate and ensure the expected ROI is achieved."
       ></meta></Helmet>

      <SolutionsHelmet />
      <OnlineCommunities />
      <MultiSectionOnlineCommunities />
      <br />
      <br />
      <OurPerspectives />
      <LearnAboutUs page="online-communities"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default solutionsOnlineCommunities;
